html {
  overflow-y: auto;
  overflow-x: auto;
}
:root {
  --primary: #000094;
  --primary-text: #ffffff;
  --secondary: #b900ff;
  --secondary-text: #ffffff;
}
